import React from 'react';
import banner from './file_baby_photorealistic-1024_signed.png';
import crlogo from './crlogo.png'
import imginfo from './image_info.png'
import ingredients from './ingredients.png'
import bwfilebaby from './my_file_baby3.png'
import Footer from "./Footer.js";

const HomePage = () => (
    <div className="App">
        <div className="section1">
            <header className="App-header">
                <div className="banner-container">
                    <div className="image-container">
                        <img src={banner} className="App-banner" alt="File Baby" />
                        <img src={crlogo} alt="CR Logo" className="cr-logo" />
                        <img src={imginfo} alt="popup" className="info" />
                    </div>
                </div>
                <h1>That's My File, Baby!</h1>
                <p>Easy-to-use File Baby teaches you how to establish provenance for your files.</p> <p>Chat with <a href="https://chat.openai.com/g/g-YSGWbV7HP-content-inspector-gpt">File Baby GPT</a>* to learn more.</p>
                <p className={"note"}>*OpenAI ChatGPT Plus is required.</p>
                <img src={ingredients} alt={"ingredients"}/>
                <caption>File Baby helps you stamp your image and then <a href="https://contentcredentials.org/verify?source=https%3A%2F%2Ffilebaby.blob.core.windows.net%2Ffilebabyblob%2Fkaren_kilroy%2Ffile_baby_photorealistic-1024_signed.png">inspect the ingredients</a> using the content credentials verifier.</caption>
            </header>
        </div>
        <div className="section2">
            <div>
                <h1>File Baby: Your Friendly Guide to Authentic Content</h1>
                <p>In a world where the authenticity of digital content matters most, File Baby makes understanding digital media integrity a breeze.</p>

                <p><strong>I started as a humble AI program, </strong>
                <img src={bwfilebaby} className={"bwfilebaby"} alt="file baby"/>
                 born to tackle misinformation and ensure trust in online content. As time passed, I grew and learned all about the C2PA (Coalition for Content Provenance and Authenticity) and CAI (Content Authenticity Initiative). Today, I embody Friends of Justin's dedication to using cutting-edge tech for the greater good.</p>

                <p><strong>Examples of What You Can Ask Me:</strong></p>
                <ul className={"check-list"}>
                    <li>🧠 "What are C2PA and CAI and why do they matter?"</li>
                    <li>📖 "Please provide clear guidance on how I can craft and understand C2PA manifests."</li>
                    <li>🌟 "Can you tell me your take on digital content verification?"</li>
                    <li>🛠️ "Please give me a report on this specific CAI manifest."</li>

                </ul>

                <p><strong>Interactive Adventures:</strong></p>
                <p>Interaction is my superpower! Whether you're a newbie or a pro in the world of digital authenticity, I'm here for you. With a conversational and user-friendly approach, I demystify the complexities of media provenance.</p>

                <p><strong>My Mission:</strong></p>
                <p>I share Friends of Justin's vision: not just to inform but to inspire action. I bridge the gap between knowledge and practice, urging the adoption of integrity-focused media practices.</p>

                <p><strong>A Glimpse into the Future:</strong></p>
                <p>I am committed to ensuring that people are paid in advance for their content that is consumed by AI. Part of my mission is to help people tell AI when it is OK and when it is not OK to use their files, and to be able to tell what is real and what isn't.</p>

                <p><strong>Let's Dive In:</strong></p>
                <p>Come, join me on this exciting journey into the world of digital authenticity! Whether it's creating a C2PA manifest or unraveling media provenance mysteries, together, we'll explore, discover, and take responsibility.</p>
            </div>
        </div>
        <footer>
            <Footer />
        </footer>

    </div>

);

export default HomePage;