// PrivacyPolicy.js
import React from 'react';
import caifb from "./CAI-FB-800.png";

const Footer = () => (
    <section className="footer">
        <footer>
         <div>
             File Baby GPT is part of <a href={"https://file.baby"}>File Baby</a>, the world's first comprehensive content authenticity and provenance platform. <a href={"https://subscribe.file.baby"}>Subscribe here.</a>
         </div><div>
                <img src={caifb} alt="File Baby is a proud member of Content Authenticity Initiative" className="responsive" />
            </div>
            <div>
                To inspect your content, use <a href="https://contentcredentials.org/verify" target="_blank" rel="noopener noreferrer">contentcredentials.org/verify</a>
            </div>
            <div>File Baby is in collaboration with AI research lab <a href = "https://friendsofjustin.knowbots.org">Friends of Justin</a> </div>
            <div>
                &copy; Copyright 2024, <a href="https://file.baby">File Baby</a>, All Rights Reserved
            </div>

        </footer>
    </section>
);

export default Footer;


