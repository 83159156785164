import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => (
    <div>

    <header className="App-header">
        <h1>Privacy Policy</h1>
        <p>Friends of Justin and File Baby do not collect or use personal data provided to us through ChatGPT for any specific purpose. Any
            information shared with us remains confidential and is not used for any marketing, analytics, or
            other purposes. We respect your privacy and adhere to the highest standards of data protection.</p>

        <p>Please note that while we don't use personal data for any purpose, it's essential to consider the
            privacy policies of any third-party services or platforms you might interact with when using
            ChatGPT.</p>

        <p>This privacy policy is subject to change, and any updates will be reflected on this page.</p>

        <p>If you have any questions or concerns about your data's privacy, please feel free to contact us at info@paybots.ai</p>
    </header>
        <div class={"section3"}>

</div>
        <Link to="/">Go to Home Page</Link>
    </div>
);

export default PrivacyPolicy;
